<template>
  <div class="tabBox flex-row-center">
    <div
      class="tabItem flex-column-center"
      v-for="(item,index) in tabList"
      :key="index"
      @click="chooseIndex(index,item)"
    >
      <img
        class="normalImg"
        :src="item.normalImg"
        v-show="index!=currentIndex"
        alt=""
      >
      <img
        v-show="index===currentIndex"
        class="chooseImg"
        :src="item.chooseImg"
        alt=""
      >
    </div>

  </div>
</template>
<script type="text/ecmascript-6">
export default {
  name: 'TabBox',
  props: {
    tabList: {
      type: Array
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    chooseIndex(index, item) {
      this.currentIndex = index
      this.$emit('chooseIndex', index, item)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './TabBox.scss';
</style>