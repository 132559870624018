<template>
  <div class="postIndexBox flex-column-center">
    <tab-nav isActive="postIndex"></tab-nav>
    <!-- <div class="searchLine flex-row-center">
      <van-field
        class="searchIpt flex-row-center"
        v-model="searchText"
        label=""
        left-icon="search"
        placeholder="搜索帖子"
      />
      <div
        class="searchBtn"
        @click="goSearchText"
      >搜索</div>
    </div> -->
    <van-swipe
      v-if="bannerList.length>0"
      class="postSwipe"
      :autoplay="3000"
      indicator-color="#DDB876"
    >
      <van-swipe-item
        v-for="(item,index) in bannerList"
        :key="index"
      >
        <img
          class="swipeItem"
          :src="item.img"
          alt=""
          @click="goPost(item.post_id)"
        >
      </van-swipe-item>

    </van-swipe>
    <div class="postTab">
      <TabBox
        :tabList="tabList"
        @chooseIndex="chooseIndex"
      ></TabBox>
    </div>
    <div
      class="topBox"
      v-show="currentTab === 0"
    >
      <div
        class="topItem"
        v-for="(item,index) in topList"
        :key="index"
        @click="goPost(item.id)"
      >
        <div class="topTitle">{{item.title}}</div>
        <div class="secondLine flex-row-center">
          <div class="topTag">置頂</div>
          <div class="commentNum">{{item.replied_cnt}}評論</div>
        </div>
      </div>
    </div>
    <van-list
      class="postList"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="initList"
    >
      <PostItem
        :iconIndex="0"
        v-for="(item,index) in postList"
        :key="index"
        :itemData="item"
      ></PostItem>
    </van-list>
    <!-- <div class="postList">
      <PostItem
        :iconIndex="0"
        v-for="(item,index) in postList"
        :key="index"
        :itemData="item"
      ></PostItem>
    </div> -->
    <div
      class="goAddBtn"
      @click="goAddPost"
    ></div>
    <div
      class="cover"
      v-if="isShowLogIn"
    ></div>
    <login
      :isLoginFade="isShowLogIn"
      v-on:changeCloseFade="closeLogIn"
    ></login>
    <div
      class="noLoginCover"
      @click="tapNoLoginCover"
      v-if="isShowNoLoginCover"
    ></div>
  </div>
</template>
<script type="text/ecmascript-6">
import tabNav from '../../components/tabNav/tabNav.vue'
import TabBox from '../../components/tabBox/TabBox.vue'
import PostItem from '../../components/postItem/PostItem.vue'
import login from '../../components/login/login.vue'

import {
  postList,
  userInfo,
  getBanner,
  getTop,
  goSearchPost
} from '../../api/service'
export default {
  name: 'PostIndex',
  components: {
    tabNav,
    TabBox,
    PostItem,
    login
  },
  data() {
    return {
      currentTab: 0,
      tabList: [
        {
          tagName: '全部',
          tagImg: '',
          normalImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/allNormal.png',
          chooseImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/allChoosed.png',
          id: 0
        },
        {
          tagName: '精選',
          tagImg: '',
          normalImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/selectNormal.png',
          chooseImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/selectChoosed.png',
          id: 1
        },
        {
          tagName: '活動',
          tagImg: '',
          normalImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/actNormal.png',
          chooseImg:
            'https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/actChoosed.png',
          id: 2
        }
      ],
      bannerList: [],
      topList: [
        {
          title: '【本週活動】新武將上線！',
          replied_cnt: 10
        },
        {
          title: '【本週活動】新武將上線！',
          replied_cnt: 10
        }
      ],
      loading: false,
      finished: false,
      postList: [],
      cursor: '',
      selected_only: 0,
      event_only: 0,
      searchText: '',
      listStatus: 'normal',
      isShowLogIn: false,
      isShowNoLoginCover: false
    }
  },
  created() {
    userInfo().then((res) => {
      if (res.code == 0) {
        localStorage.userId = res.data.id
      }
    })
    this.initBanner()
    this.initTop()
  },
  methods: {
    initBanner() {
      getBanner().then((res) => {
        if (res.code == 0) {
          this.bannerList = res.data.list
        }
      })
    },
    initTop() {
      getTop().then((res) => {
        if (res.code == 0) {
          this.topList = res.data.list
        }
      })
    },
    initList() {
      if (!localStorage.token) {
        this.isShowLogIn = true
        this.isShowNoLoginCover = true
      } else {
        this.isShowLogIn = false
        this.isShowNoLoginCover = false
      }

      this.loading = true

      postList({
        cursor: this.cursor,
        page_size: 5,
        selected_only: this.selected_only,
        event_only: this.event_only
      }).then((res) => {
        if (res.code == 0) {
          this.cursor = res.data.cursor
          res.data.list.map((item) => {
            this.postList.push(item)
          })
          this.loading = false
          if (!res.data.cursor) {
            this.finished = true
          }
        }
      })
    },
    chooseIndex(index, item) {
      this.currentTab = index
      switch (index) {
        case 0:
          this.selected_only = 0
          this.event_only = 0
          break
        case 1:
          this.selected_only = 1
          this.event_only = 0
          break
        case 2:
          this.selected_only = 0
          this.event_only = 1
          break
        default:
          break
      }
      this.loading = false
      this.finished = false
      this.postList = []
      this.cursor = ''
      this.initList()
    },
    goAddPost() {
      this.$router.push('addPost')
    },
    goPost(postId) {
      this.$router.push({
        path: 'postDetail',
        query: { postId: postId }
      })
    },
    goSearchText() {
      goSearchPost({}).then((res) => {})
    },
    closeLogIn() {
      this.initList()
      this.isShowLogIn = false
      // this.isShowNoLoginCover = false
    },
    tapNoLoginCover() {
      this.isShowLogIn = true
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/less" scoped>
@import './postIndex.scss';
</style>